/* General Styling */
body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 0;
  padding: 0;
  text-align: center;
  min-height: 100vh;
  background-color: #f9f9f9; /* Optional to give contrast */
}

/* Hero Section */
.hero-section {
  position: relative;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.banner-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80%;
  object-fit: cover;
  background: rgba(12, 12, 12, 0.9);
  z-index: 0;
}

.hero-section::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80%;
  background: rgba(0, 0, 0, 0.8); /* Adjust opacity as needed */
  z-index: 1;
}

.hero-content {
  position: relative;
  z-index: 2;
  color: #fff;
  padding: 0 10px;
  margin-top: 100px;
  margin-bottom: 10px;
  transform: translateY(-30%);
}

/* Tagline & Description */
.tagline {
  font-family: 'Poppins', sans-serif;
  font-size: 3rem;
  color: #e89905; /* Light beige */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  font-weight: 600;
  margin-bottom: 1.5rem;
}

.description {
  font-size: 1.5rem;
  font-family: 'Lora', serif;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  max-width: 900px;
  margin: 0 auto 2rem;
  animation: fadeInUp 1.2s ease-out;
}

/* Buttons */
.buttons .btn {
  display: inline-block;
  margin: 0 1.5rem;
  padding: 0.75rem 1.5rem;
  color: #fff;
  background-color: #663399;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.buttons .btn:hover,
.buttons .btn:focus {
  background-color: #552288;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

/* Mission and Vision Section */
.mission-vision {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1rem;
  margin-top: -8.5rem;
  background-color: #f9f9f9;
  z-index: 3;
}

.mission, .vision {
  flex: 1 1 45%;
  margin: 0.5rem;
  text-align: center;
  padding: .5rem;
  background: linear-gradient(145deg, #f3e7fe, #d1b3fe); /* Glossy gradient */
  border-radius: 10px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.05); /* Enhanced shadow for depth */
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

/* Adding a subtle shine effect */
.mission::before, .vision::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 150%;
  height: 150%;
  background: rgba(255, 255, 255, 0.3); /* Light sheen */
  transform: translateX(-50%) rotate(45deg);
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}
/* Adding hover effect to make the glossy shine visible */
/* .mission:hover::before, .vision:hover::before {
  opacity: 1;
} */

.mission:hover, .vision:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(235, 126, 126, 0.15);
}

/* Mission & Vision Text */
.mission h2, .vision h2 {
  background: linear-gradient(45deg, #ff8a00, #e52e71);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.25rem;
  margin-bottom: 0.25rem;
  font-family: 'Merienda', cursive;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  animation: fadeIn 1.5s ease-out;
}

.mission p, .vision p {
  font-size: 1rem;
  line-height: 1.8;
  color: #333;
  font-family: 'Poppins', sans-serif;
  text-align: justify;
  animation: fadeInUp 2s ease-in-out;
  margin-bottom: 2rem;
}

/* Hover Effects */
.mission h2:hover, .vision h2:hover {
  color: #ffdd00;
  text-shadow: 0px 0px 10px rgba(255, 255, 0, 0.5);
  cursor: pointer;
}

/* Features Preview Section */
.features-preview {
  padding: 5rem 1rem;
  text-align: center;
}

.features-preview h2 {
  color: #663399;
  margin-bottom: 1rem;
}

.features-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.feature-item {
  flex: 1 1 25%;
  margin: 1rem;
  max-width: 300px;
}

.feature-item img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  animation: fadeIn 1s ease-out;
}

.feature-item h3 {
  margin-top: 1rem;
  color: #663399;
}

.feature-item p {
  font-size: 0.95rem;
  line-height: 1.5;
}

/* Animations */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .mission-vision {
    flex-direction: column;
    margin-top: 0;
  }

  .feature-item {
    flex: 1 1 80%;
  }
}

@media (max-width: 600px) {
  .hero-content {
    padding: 0 1rem;
  }

  .tagline {
    font-size: 1.8rem;
  }

  .description {
    font-size: 1rem;
  }

  .buttons .btn {
    margin: 0.5rem;
    padding: 0.5rem 1rem;
  }

  .feature-item {
    flex: 1 1 100%;
  }
}