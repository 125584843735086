body {
  margin: 0;
  padding-top: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Global content padding to account for fixed navbar */
.main-content {
  min-height: calc(100vh - 100px); /* Subtract navbar height from 100vh */
  padding-bottom: 2rem; /* Add some bottom padding for aesthetics */
  padding-top: 100px; 
}

/* Add this to ensure all pages have proper spacing */
#root > div {
  padding-top: 90px; /* Should match the navbar height */
}

@media (max-width: 768px) {
  .page-content {
    padding-top: 90px;
  }

  #root > div {
    padding-top: 80px;
  }
}