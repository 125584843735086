/* Navbar CSS */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px;
  background-color: #663399;
  color: white;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  border-bottom: none;
  height: 90px;
  box-sizing: border-box;
}

.menu-button {
  color: white !important;
}

/* Logo and Title */
.logo-title {
  display: flex;
  align-items: center;    /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  flex-grow: 1;          /* Allow to grow and fill space */
}

.logo-image {
  width: 60px;           /* Maintain square aspect ratio */
  height: 60px;          /* Maintain square aspect ratio */
  object-fit: cover;     /* Cover the logo area */
  margin-right: 10px;    /* Space between logo and title */
}

.navbar-title {
  font-size: 18px;       /* Base font size */
  font-weight: bold;     /* Bold title */
  color: white;          /* Title color */
  margin: 0;             /* Remove default margin */
}

/* Language Toggle */
.language-toggle {
  display: flex;
  align-items: center;
}

.toggle-btn {
  background-color: #ffffff !important;
  color: #663399 !important;
  border: 0.5px solid #663399 !important;
  font-size: 14px !important;
  padding: 5px 5px !important;
  border-radius: 5px;
  margin-left: 5px;
  min-width: 80px;
  text-align: center;
}

.MuiToggleButton-root.Mui-selected {
  background-color: #663399 !important; /* Selected button color */
  color: white !important;               /* Selected button text color */
}

.MuiToggleButton-root:hover {
  background-color: #b380ff !important; /* Hover effect */
}

/* Responsive Design */
@media (max-width: 768px) {
  .navbar {
    padding: 5px 10px; /* Adjust padding for smaller screens */
  }

  .logo-title {
    flex-grow: 1; /* Ensure it can grow to fill space */
  }
  
  .logo-image {
    height: 50px;  /* Slightly smaller logo for mobile */
    margin-right: 10px; /* Space between image and title */
  }
  
  .navbar-title {
    font-size: 1.5rem; /* Increase font size slightly */
  }

  .toggle-btn {
    font-size: 12px !important; /* Adjust font size */
    padding: 3px 3px !important; /* Adjust padding */
    min-width: 60px; /* Minimum width for smaller screens */
  }
}

@media (max-width: 480px) {
  .navbar-title {
    font-size: 18px; /* Font size for very small devices */
  }
}