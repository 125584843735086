/* Scoped container styling for the privacy policy */
.privacy-policy-container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9; /* Lightened background for better contrast */
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15); /* Slightly darker and larger shadow for more depth */
}

/* Scoped heading styles within the privacy-policy-container */
.privacy-policy-container h1
{
    color: #2c3e50; /* Darkened the text for better readability */
    margin-bottom: 15px;
    text-align: center; 
}
.privacy-policy-container h2 {
    color: #2c3e50; /* Darkened the text for better readability */
    margin-bottom: 15px;
    text-align: left; 
}

/* Scoped paragraph styles within the privacy-policy-container */
.privacy-policy-container p {
    line-height: 1.8; /* Increased line-height for readability */
    margin-bottom: 15px;
    text-align: left; 
    color: #2c3e50; /* Darkened the text for better readability */
}

/* Scoped unordered list styles within the privacy-policy-container */
.privacy-policy-container ul {
    list-style-type: disc;
    margin-left: 20px;
    text-align: left; 
    color: #2c3e50; /* Matched the color of text with the paragraph text */
}

/* Scoped link styles within the privacy-policy-container */
.privacy-policy-container a {
    color: #f9f9f9; /* Blue color for the link */
    text-decoration: none;
}

.privacy-policy-container a:hover {
    text-decoration: underline;
}

/* Scoped media query for privacy-policy-container */
@media (max-width: 768px) {
    .privacy-policy-container {
        padding: 10px;
        margin: 10px;
    }

    .privacy-policy-container h1, 
    .privacy-policy-container h2 {
        font-size: 1.5rem;
    }
}