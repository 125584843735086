.footer {
  background-color: #663399;
  color: #fff;
  text-align: center;
  padding: 0.25rem 0;
}

.footer p {
  margin: 0.25rem 0;
}

.social-links {
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
}

.social-links a {
  color: #fff;
  margin: 0 0.5rem;
  text-decoration: none;
  font-size: 1.2rem;
}

.social-links a:hover {
  color: #ffcc00;
}

.footer-links {
  display: flex;
  justify-content: center; /* Centers links horizontally */
  align-items: center; /* Centers links vertically */
  gap: 30px; /* Adds spacing between links */
  margin-top: 2px; /* Adjusted for proper spacing from other elements */
}

.footer-link {
  color: #fff; /* White text color for links */
  text-decoration: none;
  font-size: 1rem;
}

.footer-link:hover {
  text-decoration: underline;
  color: #4CAF50; /* Hover effect color */
}