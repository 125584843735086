/* Ensure space for the navbar at the top */
.download {
  position: relative;
  padding: 1.5rem;
  text-align: center;
  background-image: url('../assets/download.png'); /* Make sure the path is correct */
  background-size: 25%; /* Ensure the image covers the entire section */
  background-position: center top; /* Position it at the top first */
  background-position-y: calc(4rem);
  background-repeat: no-repeat;
  color: #fff;
  min-height: 77vh; /* Make sure the section takes up the full height of the viewport */
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Move content to the bottom */
  align-items: center;
}


.download::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Dark overlay for better text readability */
  z-index: 1;
}

.download h2,
.download p,
.download ul {
  position: relative;
  z-index: 1; /* Ensure the text is above the overlay */
  margin-bottom: 1rem;
}

.download ul {
  list-style-type: none;
  padding: 0;
}

.download li {
  margin: 1rem;
  display: inline-block;
}
/* Google Play Store Button */
.download a {
  display: inline-block;
  color: #fff;
  background: linear-gradient(135deg, #663399, #a066cc); /* Gradient for a vibrant look */
  padding: 0.75rem 1.5rem;
  text-decoration: none;
  border-radius: 50px; /* Rounded button edges */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3); /* Subtle shadow */
  transition: all 0.3s ease;
  font-weight: bold;
  letter-spacing: 1px;
}

.download a:hover {
  background: linear-gradient(135deg, #552288, #8e55bb);
  transform: translateY(-3px); /* Adds a lift effect */
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.4); /* Enhanced shadow on hover */
}

/* Coming Soon Button */
.coming-soon {
  display: inline-block;
  color: #fff;
  background: linear-gradient(135deg, #444, #555); /* Gradient gray */
  padding: 0.75rem 1.5rem;
  border-radius: 50px;
  font-style: italic;
  font-weight: bold;
  opacity: 0.8;
  cursor: not-allowed;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
}

/* Text Hover Effect for Coming Soon (Optional) */
.coming-soon:hover {
  background: linear-gradient(135deg, #333, #444);
  opacity: 1;
}