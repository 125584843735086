/* src/components/Features.css */
/* Ensure space for the navbar at the top */
.content {
  margin-top: 100px; /* Adjust based on the navbar height */
}

.features {
    padding: 2rem;
    text-align: center;
  }
  
  .features h2 {
    color: #663399;
    margin-bottom: 1rem;
  }
  
  .features ul {
    list-style-type: none;
    padding: 0;
  }
  
  .features li {
    margin: 1rem 0;
    font-size: 1.1rem;
    position: relative;
    padding-left: 2rem;
  }
  
  .features li::before {
    content: '✓';
    position: absolute;
    left: 0;
    color: #663399;
    font-weight: bold;
  }