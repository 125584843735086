/* General Styling */
.safety-standards {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f9;
    color: #333;
  }
  
  header {
    background-color: #4CAF50;
    color: white;
    padding: 20px;
    text-align: center;
  }
  
  h1 {
    margin: 0;
  }
  
  section {
    padding: 20px;
    margin: 20px 10%;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    color: #4CAF50;
  }
  
  .safety-standards ul {
    list-style-position: outside; /* Ensures bullet points are outside */
    margin-left: 20px; /* Adds spacing between bullets and text */
    text-align: left; /* Aligns the text to the left */
  }
  
 
  .safety-standards h2,
  .safety-standards p {
    text-align: left; /* Keeps headings and paragraphs centered */
  }
  
  footer {
    background-color: #4CAF50;
    color: white;
    text-align: center;
    padding: 10px;
    position: fixed;
    width: 100%;
    bottom: 0;
  }
  
  a {
    color: rgb(49, 2, 29);
    text-decoration: none;
  }
  
  /* Form Styling */
  form {
    margin-top: 20px;
  }
  
  textarea {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
 